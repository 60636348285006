import React, { useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Button } from '../../components/ui/button/Button';
import { Separator } from '../../components/ui/separator/Separator';
import { useNavigate } from 'react-router';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import { useGetAllMatchChatDisputesQuery } from '../../slices/matchApiSlice';
import { dateAndTimeFormatter } from '../../utils/dateFormatter';
import { DisputeStatus } from '../../utils/constants';

const DashboardMatchChatDisputes = () => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllMatchChatDisputesQuery({
    limit: 3,
    filters: JSON.stringify({ status: DisputeStatus.NotChecked }),
  });

  const handleShowMore = () => {
    navigate('/match-chat-disputes');
  };

  const handleDisputeClick = (matchId: number) => {
    const dispute = data.find((d: any) => d.matchId === matchId);
    const seasonId = dispute.Match.SeasonWeek.Season.id;
    const link = dispute.Match.SeasonWeek.Season.SeasonType.leaderboard
      ? `/seasons/view/${seasonId}/chat/${matchId}`
      : `/match/${matchId}`;
    if (matchId) {
      navigate(link);
    }
  };
  const configureListView = [
    {
      name: 'Reported by',
      active: true,
      property: 'firstName',
    },
    {
      name: 'Message',
      active: true,
      property: 'role',
    },
    {
      name: 'Status',
      active: true,
      property: 'status',
    },
    {
      name: 'Date',
      active: true,
      property: 'formatedStartDate',
    },
  ];

  const [listView, setListView] = useState(configureListView);
  if (isLoading) return <h1>Data loading</h1>;
  return (
    <>
      <div className='flex flex-col gap-10 border-1 rounded-xl'>
        <h1 className='text-2xl ml-6 mt-6 font-bold'>Open Match Chat Disputes</h1>
        <Separator />
        <div className='grid grid-cols-3  mx-5'>
          <section className='col-span-3 bg-white rounded-lg mt-3 '>
            <div className='flex  flex-col'>
              <div className='-my-2 overflow-x-auto '>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    {/* <table className='min-w-full divide-y divide-gray-200'> */}
                    <TableContainer>
                      <TableHead
                        tableList={listView
                          .map((l) => l.name)
                          .filter(
                            (t) =>
                              listView.find((d) => d.name.toLowerCase() === t.toLowerCase())
                                ?.active,
                          )}
                      />
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {data &&
                          data?.map((dispute: any, i: number) => {
                            return (
                              <React.Fragment key={i}>
                                <tr
                                  className='cursor-pointer'
                                  key={i}
                                  onClick={() => handleDisputeClick(dispute.matchId)}
                                >
                                  {listView.map((p) => {
                                    return p.name === 'Reported by' ? (
                                      <TableTd>
                                        <span>{dispute.user.firstName}</span>
                                      </TableTd>
                                    ) : p.name === 'Message' ? (
                                      <TableTd extraClass='max-w-[15ch] truncate'>
                                        {dispute.message}
                                      </TableTd>
                                    ) : p.name === 'Status' ? (
                                      <TableTd extraClass='max-w-[15ch] truncate'>
                                        {`${
                                          dispute.status === DisputeStatus.Checked
                                            ? 'Closed'
                                            : 'Open'
                                        }`}
                                      </TableTd>
                                    ) : p.name === 'Date' ? (
                                      <TableTd>
                                        {dateAndTimeFormatter(dispute.created_at).replace(';', '')}
                                      </TableTd>
                                    ) : (
                                      <TableTd key={p.property}>{dispute[p.property]}</TableTd>
                                    );
                                  })}
                                </tr>
                              </React.Fragment>
                            );
                          })}
                      </tbody>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10 mb-5  max-h-[1000px] px-10'>
              <div className='mt-1 grid grid-cols-3 items-center text-gray-400'>
                <hr className='border-gray-200' />
                <div style={{ gridTemplateRows: '1fr 0.5fr 1fr' }}>
                  <div className='flex items-center justify-center'>
                    <Button size='sm' variant='ghost' onClick={() => handleShowMore()}>
                      <p className='text-rose-700 font-semibold'>Go to</p>{' '}
                      <BsChevronRight className='ml-4 text-rose-700 stroke-1' />
                    </Button>
                  </div>
                </div>
                <hr className='border-gray-300' />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default DashboardMatchChatDisputes;
