import { dateToLocal } from '../../../../utils/dateFormatter';

export function GameRaceScreenshotRow({
  username = '',
  time = '',
  url = '',
  date = '',
}: {
  username?: string;
  time?: string;
  url?: string;
  date?: string;
}) {
  return (
    <div className='flex w-full justify-between rounded-lg border-2 bg-gray-100  p-2 py-5'>
      <p className='text-sm'>
        <div className='mb-1'>
          <span className='font-bold'>{username}</span> claims that the time is
          <span> {time} </span>
        </div>
        <span> Date: {dateToLocal(new Date(date), 'llll zz')} </span>
      </p>
      {url && (
        <span className='text-sm flex gap-5'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={url}
            className='underline text-black
                        '
          >
            View Attachments
          </a>
        </span>
      )}
    </div>
  );
}
