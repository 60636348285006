import { useState } from 'react';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import { Separator } from '../../components/ui/separator/Separator';
import React from 'react';
import TableTd from '../../components/table-td/table-td';
import Pagination from '../../components/pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllMatchChatDisputesQuery } from '../../slices/matchApiSlice';
import { dateAndTimeFormatter } from '../../utils/dateFormatter';
import { Button } from '../../components/ui/button/Button';
import { DisputeStatus } from '../../utils/constants';

const MatchChatDisputes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('Open Disputes');
  const [buttonText, setButtonText] = useState('View Resolved Disputes');
  const [disputeStatus, setDisputeStatus] = useState(DisputeStatus.NotChecked);
  const { data, error, isLoading } = useGetAllMatchChatDisputesQuery({
    page: searchParams.get('page') ? +searchParams.get('page')! : 1,
    filters: JSON.stringify({ status: disputeStatus }),
  });
  const configureListView = [
    {
      name: 'Reported by',
      active: true,
      property: 'firstName',
    },
    {
      name: 'Message',
      active: true,
      property: 'role',
    },
    {
      name: 'Date',
      active: true,
      property: 'formatedStartDate',
    },
  ];
  const handleClick = () => {
    if (title === 'Open Disputes') {
      setTitle('Resolved Disputes');
      setDisputeStatus(DisputeStatus.Checked);
      setButtonText('View Open Disputes');
    } else {
      setTitle('Open Disputes');
      setDisputeStatus(DisputeStatus.NotChecked);
      setButtonText('View Resolved Disputes');
    }
  };

  const handleNavigate = (dispute: any) => {
    if (dispute.status === DisputeStatus.NotChecked) {
      const seasonId = dispute.Match.SeasonWeek.Season.id;
      const link = dispute.Match.SeasonWeek.Season.SeasonType.leaderboard
        ? `/seasons/view/${seasonId}/chat/${dispute.matchId}`
        : `/match/${dispute.matchId}`;
      navigate(link);
    }
  };
  const [listView, setListView] = useState(configureListView);
  if (isLoading) return <h1>{title}</h1>;
  return (
    <>
      <div className='listViewContainer fullPage rounded-xl border-1'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>{title}</h2>
          </div>
        </div>
        <Separator />
        <div>
          <Button variant={'subtle'} onClick={() => handleClick()}>
            {buttonText}
          </Button>
        </div>
        <div className='flex flex-col flex-1 justify-between'>
          <TableContainer>
            <TableHead tableList={listView.map((l) => l.name)} />
            <tbody className='bg-white divide-y divide-gray-200'>
              {data &&
                data.data?.map((dispute: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <tr onClick={() => handleNavigate(dispute)} key={i}>
                        {listView.map((p) => {
                          return p.name === 'Reported by' ? (
                            <TableTd key={i}>
                              <span>{dispute.user.firstName}</span>
                            </TableTd>
                          ) : p.name === 'Message' ? (
                            <TableTd key={i} extraClass='max-w-[15ch] truncate'>
                              {dispute.message}
                            </TableTd>
                          ) : p.name === 'Date' ? (
                            <TableTd key={i}>
                              {dateAndTimeFormatter(dispute.created_at).replace(';', '')}
                            </TableTd>
                          ) : (
                            <TableTd key={p.property}>{dispute[p.property]}</TableTd>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </TableContainer>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
    </>
  );
};

export default MatchChatDisputes;
