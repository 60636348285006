import { Link, useNavigate } from 'react-router-dom';
import { MatchGameRaceStatus, TeamSides, UserRoles } from '../../../utils/constants';
import { MatchResultCircle } from './match-result-circle';
import { FiShare } from 'react-icons/fi';
import CanIView from '../../../components/can-i-view/can-i-view';
import { AiFillEdit } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { MdCloudDone } from 'react-icons/md';
import { RiScreenshot2Line } from 'react-icons/ri';
import { useGetMatchGameRaceScreenshotsQuery } from '../../../slices/matchApiSlice';
import { selectMatchData } from '../../../slices/matchSlice';

type MatchGameRaceRowProps = {
  matchGameRaces: any[];
  canEdit: boolean;
  matchInProgress: boolean;
  home_team: any;
  away_team: any;
};

function MatchGameRaceRow({
  matchGameRaces,
  canEdit,
  matchInProgress,
  home_team,
  away_team,
}: MatchGameRaceRowProps) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  console.log('matchData', matchData);
  const userTeam = home_team.User.find((u: any) => u.id === user.id)
    ? TeamSides.Home
    : away_team.User.find((u: any) => u.id === user.id)
    ? TeamSides.Away
    : TeamSides.None;
  const isHomeOgma =
    user.ogma_org.some((userOrg: any) =>
      home_team.organizations.some((homeOrg: any) => userOrg.id === homeOrg.id),
    ) || user.ogma_org.map((org: any) => org.id).includes(home_team.School?.organization?.id);
  const isAwayOgma =
    user.ogma_org.some((userOrg: any) =>
      away_team.organizations.some((awayOrg: any) => userOrg.id === awayOrg.id),
    ) || user.ogma_org.map((org: any) => org.id).includes(away_team.School?.organization?.id);
  const showHomeTeamScores =
    userTeam === TeamSides.Home ||
    user.roles === UserRoles.Admin ||
    matchGameRaces.every((race: any) => race.status === MatchGameRaceStatus.Closed) ||
    isHomeOgma;
  const showAwayTeamScores =
    userTeam === TeamSides.Away ||
    user.roles === UserRoles.Admin ||
    matchGameRaces.every((race: any) => race.status === MatchGameRaceStatus.Closed) ||
    isAwayOgma;

  if (!matchGameRaces?.length) return null;
  return (
    <>
      {matchGameRaces
        .slice()
        .sort((a: any, b: any) => b.id - a.id)
        .map((race: any, i) => {
          const {
            data: matchGameRaceScreenShots,
            isLoading,
            isError,
          } = useGetMatchGameRaceScreenshotsQuery(race.id);

          return (
            <div
              key={race.id}
              className={`flex self-center w-[90%] uppercase text-[12px] ${
                !(i % 2) ? 'bg-gray-200' : 'bg-gray-100'
              }`}
            >
              <div className='flex flex-col mx-2 justify-between w-[100%]'>
                <div className='mr-4 flex justify-center'>{`Race ${i + 1}`}</div>
                <div className='flex min-w-[50%] gap-2 justify-between items-center'>
                  {matchData?.home_team_active?.map((userId: number) => {
                    const matchCircleUser = home_team.User.find((u: any) => u.id === userId);
                    const screenShot = matchGameRaceScreenShots?.find(
                      (s: any) => s.user_id === userId,
                    );
                    const canEdit =
                      user.roles === UserRoles.Admin
                        ? true
                        : user.roles === UserRoles.Ogma
                        ? isHomeOgma
                        : user.roles === UserRoles.Student
                        ? (user.id === userId || user.id === matchData?.home_team?.[0]?.captain) &&
                          matchInProgress &&
                          !screenShot
                        : false;
                    return (
                      <div className='flex flex-col items-center justify-center w-[65px] overflow-hidden text-ellipsis'>
                        <div className='flex justify-center w-[100%] h-[30px]'>
                          {matchCircleUser && (
                            <h5 className='text-[12px] leading-tight w-full break-words line-clamp-2'>
                              {matchCircleUser.username}
                            </h5>
                          )}
                        </div>
                        <MatchResultCircle
                          key={userId}
                          extraClass={`!w-[65px] !my-1 ${canEdit ? 'cursor-pointer' : ''} ${
                            screenShot ? 'bg-green-200' : ''
                          }`}
                          result={
                            screenShot?.status === MatchGameRaceStatus.Closed &&
                            showHomeTeamScores &&
                            parseFloat(screenShot?.time).toFixed(3)
                              ? parseFloat(screenShot?.time).toFixed(3)
                              : '-'
                          }
                          onClick={() => {
                            if (canEdit) navigate(`./edit-game-race-times/${race.id}/${userId}`);
                          }}
                        />
                      </div>
                    );
                  })}
                  |
                  {matchData?.away_team_active?.map((userId: number) => {
                    const matchCircleUser = away_team.User.find((u: any) => u.id === userId);
                    const screenShot = matchGameRaceScreenShots?.find(
                      (s: any) => s.user_id === userId,
                    );
                    const canEdit =
                      user.roles === UserRoles.Admin
                        ? true
                        : user.roles === UserRoles.Ogma
                        ? isAwayOgma
                        : user.roles === UserRoles.Student
                        ? (user.id === userId || user.id === matchData?.away_team?.[0]?.captain) &&
                          matchInProgress &&
                          !screenShot
                        : false;
                    return (
                      <div className='flex flex-col items-center justify-center w-[65px] overflow-hidden text-ellipsis'>
                        <div className='flex justify-center w-[100%] h-[30px]'>
                          {matchCircleUser && (
                            <h5 className='text-[12px] leading-tight w-full break-words line-clamp-2'>
                              {matchCircleUser.username}
                            </h5>
                          )}
                        </div>
                        <MatchResultCircle
                          key={userId}
                          extraClass={`!w-[65px] !my-1 ${canEdit ? 'cursor-pointer' : ''} ${
                            screenShot ? 'bg-green-200' : ''
                          }`}
                          result={
                            screenShot?.status === MatchGameRaceStatus.Closed &&
                            showAwayTeamScores &&
                            parseFloat(screenShot?.time).toFixed(3)
                              ? parseFloat(screenShot?.time).toFixed(3)
                              : '-'
                          }
                          onClick={() => {
                            if (canEdit) navigate(`./edit-game-race-times/${race.id}/${userId}`);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default MatchGameRaceRow;
