import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { ChatMatch } from './chat-match';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { SocketActions } from '../../../utils/constants';
import {
  useLazyGetSingleMatchInfoQuery,
  useLazyInitChatMessagesQuery,
} from '../../../slices/matchApiSlice';
import useMatchStudentTeamId from '../../../hooks/use-match-student-team-id';
import useChatTabs from '../../../hooks/use-chat-tabs';
import { initChatMessages, setMatchData } from '../../../slices/matchSlice';

const LeaderboardMatchChat: React.FC = () => {
  const user = useSelector(selectUser);
  const { matchId } = useParams<{ matchId: string }>();
  const dispatch = useDispatch();
  const [getMatchInfo, { error, data, isLoading, isError }] = useLazyGetSingleMatchInfoQuery();
  const [showChat, setShowChat] = useState(false);
  const [tempChat, setTempChat] = useState('');
  const [ogmaTeam, setOgmaTeam] = useState<string[]>([]);
  const [tabs, rooms, partOfTeam] = useChatTabs();
  const studentMatchData = useMatchStudentTeamId();

  const [page, setPage] = useState<number>();
  const [getMessages, { data: messageData, isLoading: messageIsLoading, isSuccess }] =
    useLazyInitChatMessagesQuery();

  useEffect(() => {
    getMatchInfo(matchId);
  }, [matchId]);

  useEffect(() => {
    if (!data) return;
    dispatch(setMatchData(data));
    dispatch({
      type: SocketActions.Connect,
      payload: {
        id: matchId,
        rooms,
        teamId: data.home_team[0].id,
        team: user?.team?.length ? studentMatchData[0] : undefined,
      },
    });
    getMessages({ id: matchId, page });
    return () => {
      dispatch({ type: SocketActions.Disconnect, payload: { id: user?.id } });
      dispatch(setMatchData({}));
      dispatch(initChatMessages([]));
    };
  }, [data]);

  function sendChat(message: any, team: boolean | number) {
    let payload: any;
    payload = {
      isTeam: false,
      room: matchId,
      user_id: user?.id,
      user_roles: user?.roles,
      ogma_team: ogmaTeam.join(','),
      username: user?.username,
      team: 'T',
      message,
      image: user.image ? user.image : '',
      time: new Date().toTimeString().split(' ')?.[0],
    };

    dispatch({ type: SocketActions.SendChat, payload });
  }

  return (
    <>
      <OutsideClick />

      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-[80vw] h-[90vh] mx-auto  bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <ChatMatch
          setShowChat={setShowChat}
          sendChat={sendChat}
          tempChat={tempChat}
          setTempChat={setTempChat}
        />
      </div>
      <Outlet />
    </>
  );
};

export default LeaderboardMatchChat;
