import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import {
  useGetCanSubmitGameScreenshotQuery,
  useGetMatchGameRaceScreenshotsQuery,
  useGetSingleMatchInfoQuery,
  useSubmitGameRaceTimeMutation,
} from '../../../slices/matchApiSlice';
import { DragDropFile } from './drag-drop-file/drag-drop-file';
import ModalFooter from '../../../components/modal-footer';
import ModalHeader from '../../../components/modal-header';
import { CustomError } from '../../../utils/custom-types';
import { useStateContext } from '../../../contexts/ContextProvider';
import { AvatarImage } from '../../../components/avatar-image';
import { useGetUserByIdQuery } from '../../../slices/userApiSlice';
import { GameRaceScreenshotRow } from './component/game-race-screenshot-row';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { UserRoles } from '../../../utils/constants';

const EditGameRaceTimes = () => {
  const user = useSelector(selectUser);
  const [time, setTime] = useState('');
  const param = useParams();
  const { error, data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);
  const {
    data: matchGameRaceScreenShots,
    isLoading: matchGameRaceScreenShotsLoading,
    isError: matchGameRaceScreenShotsError,
  } = useGetMatchGameRaceScreenshotsQuery(param.rid);
  const {
    isLoading: canViewLoading,
    error: canViewError,
    isError: canViewIsError,
  } = useGetCanSubmitGameScreenshotQuery(param?.id);

  const { data: userData } = useGetUserByIdQuery(param.uid, { skip: !param.uid });
  const [file, setFile] = useState<File | null>(null);

  const [
    submitGameRaceTimeScreenshot,
    { error: error2, isSuccess: upIsSuccess, isError: upIsError, isLoading: upIsLoading },
  ] = useSubmitGameRaceTimeMutation();

  const [errorMsg, setErrorMsg] = useState('');
  const handleSubmit = (values: any) => {
    if (!file && user.roles !== UserRoles.Admin) {
      setErrorMsg('Please upload an image!');
      return;
    }
    const formData = new FormData();
    formData.append('time', time.toString());
    formData.append('userId', param.uid!);
    if (file) {
      formData.append('image', file);
    }
    submitGameRaceTimeScreenshot({
      id: +param.rid!,
      body: formData,
    });
    return;
  };
  useEffect(() => {
    if (file) setErrorMsg('');
  }, [file]);
  if (upIsSuccess) return <Navigate to='../' />;
  if (isError || upIsError || canViewIsError) {
    const errorMessage = upIsError
      ? (error2 as CustomError).data.message
      : canViewIsError
      ? (canViewError as CustomError).data.message
      : (error as CustomError).data.message;
    return <Navigate to='/went-wrong' state={{ message: errorMessage }} />;
  }

  if (isLoading || canViewLoading) return <p>Loading...</p>;

  return (
    <>
      <OutsideClick />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl'>
                {/* content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-[80vh] overflow-auto'>
                  {/* header*/}
                  <ModalHeader name={'Enter Time'} />
                  <div className='relative p-6 flex flex-col'>
                    <div className='flex justify-center gap-10'>
                      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[400px]'>
                        <AvatarImage source={data.home_team[0].image} size='xl' />
                        <div className='flex flex-col justify-center flex-1 items-center '>
                          <h2 className='text-sm font-bold'>{userData?.username}</h2>
                        </div>
                        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
                          <input
                            onChange={(e) => {
                              const value = e.target.value;

                              if (
                                value === '' ||
                                value === '.' ||
                                /^\d{1,3}(\.\d{0,3})?$/.test(value)
                              )
                                setTime(value);
                            }}
                            type='text'
                            value={time}
                            className='w-[80px] text-center font-bold focus:outline-none active:outline-none'
                            placeholder='0.000'
                          />
                        </div>
                      </div>
                    </div>
                    <h1 className='self-center text-red-500'>
                      Enter time with 3 decimal places. (Eg. 123.456)
                    </h1>
                  </div>
                  <div className='flex flex-col p-5 gap-5'>
                    {matchGameRaceScreenShots
                      ?.filter((ss: any) => ss.user_id == param.uid)
                      ?.map((ss: any) => (
                        <GameRaceScreenshotRow
                          key={ss.id}
                          username={ss.SubmittedUser?.username}
                          time={ss.time}
                          url={ss.image_url}
                          date={ss.created_at}
                        />
                      ))}
                  </div>
                  <div className='flex flex-col p-5 gap-5 drag-upload'>
                    <p className='text-red-700'>{errorMsg}</p>
                    <DragDropFile file={file} setFile={setFile} />
                  </div>
                  {/* footer*/}
                  <ModalFooter isLoading={upIsLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditGameRaceTimes;
