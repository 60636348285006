import { AiOutlineClose } from 'react-icons/ai';
import { OutsideClick } from '../../../components/outside-click';
import { useGetAllScreenshotsByMatchIdQuery } from '../../../slices/matchApiSlice';
import { userRoleNames } from '../../../utils/constants';
import { dateToLocal } from '../../../utils/dateFormatter';

const SeeAllScreenshoots = ({
  matchId,
  teamName,
  // isLoading,
  close,
}: {
  matchId: number;
  teamName: string;
  // isLoading: boolean;
  close: () => void;
}) => {
  const {
    data: screenshotData,
    isLoading,
    isError,
    error,
  } = useGetAllScreenshotsByMatchIdQuery(matchId);

  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick setFunc={close} />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <span className='font-bold text-xl'>Screenshots</span>
        <div className='absolute top-2 right-2'>
          <div onClick={close} className='cursor-pointer'>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </div>
        </div>
        <div className='py-4 flex flex-1 flex-col justify-center items-center gap-8 w-full'>
          <div className='flex flex-col flex-1 gap-2'>
            {!screenshotData?.MatchGame?.[0]?.MatchGameScreenshot?.length ? (
              <p>No screenshots submitted</p>
            ) : (
              <></>
            )}
            {screenshotData?.MatchGame?.[0]?.MatchGameScreenshot?.map((screenshots: any) => (
              <div className='flex gap-10 flex-1 border rounded-xl w-full p-2'>
                <p className='text-sm content-center'>
                  {dateToLocal(new Date(screenshots.created_at), 'llll')}
                </p>
                <p>Score: {screenshots.ht_result}</p>
                <p>
                  {screenshots.user.firstName} {screenshots.user.lastName}
                </p>
                <span className='text-sm flex gap-5'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={screenshots.url}
                    className='underline text-black
                        '
                  >
                    View Attachments
                  </a>
                  {/* <span className="font-sm text-gray-500">10 minutes ago</span> */}
                </span>
              </div>
            ))}
          </div>
          <div className='flex gap-2'>
            {/* <SubmitButton onSubmit={() => console.log('test')} title='Submit' isLoading={isLoading} /> */}
            <div
              onClick={close}
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100 cursor-pointer'
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SeeAllScreenshoots;
