import React from 'react';
export function MatchResultCircle({
  result,
  win,
  dblForfeit,
  extraClass,
  onClick,
}: {
  result: string | number;
  win?: Boolean;
  dblForfeit?: Boolean;
  extraClass?: string;
  onClick?: () => void;
}) {
  return (
    <div
      className={`flex bg-red-500 justify-center items-center bg-white rounded-full w-[30px] h-[30px] aspect-square p-1 text-xs font-bold ${
        win ? '' : ' '
      } ${extraClass} bg-red-500`}
      onClick={onClick}
    >
      {result}
    </div>
  );
}
