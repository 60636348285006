import { Link, Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import { AiOutlineClose } from 'react-icons/ai';
import {
  useEditSeasonStageGroupsMutation,
  useViewSingleSeasonStageQuery,
} from '../../../../slices/seasonApiSlice';
import { closestCorners, DndContext, DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import { useEffect, useState } from 'react';
import { SubmitButton } from '../../../../components/submit-button';

interface DraggableTeamProps {
  id: string;
  teamName: string;
  schoolName: string;
}

const DraggableTeam = ({ id, teamName, schoolName }: DraggableTeamProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
  });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className='w-[200px] px-2 truncate whitespace-nowrap overflow-hidden mb-2 bg-gray-200 rounded hover:bg-gray-500 hover:text-white'
    >
      <div>{teamName}</div>
      <span className='text-xs'> {schoolName ?? 'Co-Op'}</span>
    </div>
  );
};

interface Team {
  id: number;
  teamName: string;
  Standing: any;
  School: { name: string };
}

interface DroppableGroupProps {
  id: string;
  teams: Team[];
  matches?: any[];
  handleDragEnd: (event: any) => void;
}

const DroppableGroup = ({ id, teams, matches, handleDragEnd }: DroppableGroupProps) => {
  const { setNodeRef } = useDroppable({
    id,
  });
  return (
    <div ref={setNodeRef} className='flex flex-col items-center border-2 border-black p-4'>
      {teams.map((team) => {
        const canDrag =
          !matches ||
          !matches.some((match: any) => {
            return match.home_team[0]?.id === team.id || match.away_team[0]?.id === team.id;
          });
        return canDrag ? (
          <DraggableTeam
            key={`${team.id}-T`}
            id={`${team.id}-T`}
            teamName={team.teamName}
            schoolName={team.School?.name}
          />
        ) : (
          <div
            key={team.id}
            className='w-[200px] px-2 truncate whitespace-nowrap overflow-hidden mb-2 bg-gray-700 text-white rounded'
          >
            <div>{team.teamName}</div>
            <span className='text-xs text-white'> {team.School?.name ?? 'Co-Op'}</span>
          </div>
        );
      })}
    </div>
  );
};

const EditGroups = () => {
  const param = useParams();
  const { data } = useViewSingleSeasonStageQuery(+(param.stageId || 0));
  const [groups, setGroups] = useState(data?.SeasonStageGroup || []);
  const [updateGroups, { isLoading, isSuccess }] = useEditSeasonStageGroupsMutation();
  const [editGroupNameId, setEditGroupNameId] = useState(0);
  const [activeId, setActiveId] = useState(null);
  const [teamsWithoutGroup, setTeamsWithoutGroup] = useState<Team[]>([]);

  useEffect(() => {
    if (data?.SeasonStageGroup) {
      setGroups(data.SeasonStageGroup);
    }
  }, [data]);

  useEffect(() => {
    if (data?.teams) {
      setTeamsWithoutGroup(
        data.teams.filter(
          (team: any) =>
            !groups.some((group: any) => group.teams.some((t: any) => t.id === team.id)),
        ),
      );
    }
  }, [data, groups]);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const activeId = Number(active.id.split('-')[0]);
    const overId = Number(over.id.split('-')[0]);
    if (over && active.id !== over.id) {
      const sourceGroupIndex = groups.findIndex((group: any) =>
        group.teams.some((team: any) => team.id === activeId),
      );
      const destinationGroupIndex = groups.findIndex((group: any) => group.id === overId);
      if (
        sourceGroupIndex !== -1 &&
        destinationGroupIndex !== -1 &&
        sourceGroupIndex !== destinationGroupIndex
      ) {
        const sourceGroup = groups[sourceGroupIndex];
        const destinationGroup = groups[destinationGroupIndex];

        const sourceTeams = sourceGroup.teams.filter((team: any) => team.id !== activeId);
        const movedTeam = sourceGroup.teams.find((team: any) => team.id === activeId);

        if (movedTeam) {
          const updatedGroups = [...groups];

          updatedGroups[sourceGroupIndex] = {
            ...sourceGroup,
            teams: sourceTeams,
          };
          updatedGroups[destinationGroupIndex] = {
            ...destinationGroup,
            teams: [...destinationGroup.teams, movedTeam],
          };

          setGroups(updatedGroups);
        }
      } else if (sourceGroupIndex === -1 && destinationGroupIndex !== -1) {
        const destinationGroup = groups[destinationGroupIndex];
        const movedTeam = teamsWithoutGroup.find((team: any) => team.id === activeId);
        if (movedTeam) {
          const updatedGroups = [...groups];
          updatedGroups[destinationGroupIndex] = {
            ...destinationGroup,
            teams: [...destinationGroup.teams, movedTeam],
          };
          setGroups(updatedGroups);
        }
      }
    }

    setActiveId(null);
  };

  const onSubmit = () => {
    const updatedGroups = groups.map((group: any) => ({
      groupId: group.id,
      groupName: group.name,
      teamIds: group.teams.map((team: any) => team.id),
    }));
    updateGroups({ stageId: +param.stageId!, body: { groups: updatedGroups } });
  };

  if (isSuccess) return <Navigate to='../' />;
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <OutsideClick />
      <DndContext
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-h-[90vh] overflow-auto bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
          <div className='absolute top-2 right-2'>
            <Link to='../' className=''>
              <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
            </Link>
          </div>
          <h1 className='text-2xl font-semibold'>Edit Groups</h1>
          <p className='text-sm text-gray-500'>
            Note: You are not able to move teams who have existing matches within this stage.
          </p>
          <div className='grid grid-cols-3 gap-4 overflow-auto w-max'>
            {groups?.map((group: any) => {
              const matches = data.SeasonWeek?.flatMap((week: any) => week.match);
              return (
                <div className='flex flex-col items-center' key={group.id}>
                  {editGroupNameId !== group.id ? (
                    <h2
                      onClick={() => {
                        setEditGroupNameId(group.id);
                      }}
                    >
                      {group.name}
                    </h2>
                  ) : (
                    <input
                      type='text'
                      value={group.name}
                      autoFocus
                      onChange={(e) => {
                        const newName = e.target.value;
                        setGroups((prevGroups: any) =>
                          prevGroups.map((g: any) =>
                            g.id === group.id ? { ...g, name: newName } : g,
                          ),
                        );
                      }}
                      onBlur={() => setEditGroupNameId(0)}
                    />
                  )}
                  <DroppableGroup
                    id={`${group.id}-G`}
                    teams={group.teams}
                    handleDragEnd={handleDragEnd}
                    matches={matches}
                  />
                </div>
              );
            })}
            {data && teamsWithoutGroup.length > 0 && (
              <div className='flex flex-col items-center p-4' id='ungrouped'>
                <h2>Ungrouped Teams</h2>
                <DroppableGroup
                  id={'0-G'}
                  teams={teamsWithoutGroup}
                  handleDragEnd={handleDragEnd}
                />
              </div>
            )}
          </div>{' '}
          <div className='flex gap-2'>
            <SubmitButton onSubmit={onSubmit} title='Submit' isLoading={isLoading} />
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              Cancel
            </Link>
          </div>
        </div>
        <DragOverlay>
          {activeId ? (
            <div className='w-[200px] truncate whitespace-nowrap overflow-hidden mb-2 bg-gray-200 rounded hover:bg-gray-500 hover:text-white'>
              {
                groups
                  .flatMap((group: any) => group.teams)
                  .find((team: any) => team.id === activeId)?.teamName
              }
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};
export default EditGroups;
