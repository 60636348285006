import Bracket from '../../../routes/tournaments/routes/torunament-bracket/Bracket';
import NewTeamModal from '../../../features/listview/NewModals/NewTeamModal';
import { Navigate, RouteObject } from 'react-router-dom';
import MyAccount from '../../../features/Settings/AdminSettings/MyAccount';
import NotificationSettings from '../../../features/Settings/AdminSettings/Notifications';
import SecuritySettings from '../../../features/Settings/AdminSettings/Security';
import Settings from '../../../features/Settings/AdminSettings/Settings';
import Modal from '../../../features/listview/NewModals/Modal';
import NewOrganizationModal from '../../../features/listview/NewModals/NewOrganizationModal';
import NewSchoolModal from '../../../features/listview/NewModals/NewSchoolModal';
import Organizations from '../../../features/listview/Organizations';
import Schools from '../../../features/listview/Schools';
import UpdateOrganizationModal from '../../../features/listview/UpdateModal/UpdateOrganizationModal';
import UpdateSchoolModal from '../../../features/listview/UpdateModal/UpdateSchoolModal';
import UpdateTeamModal from '../../../features/listview/UpdateModal/UpdateTeamModal';
import ViewCoaches from '../../../features/listview/coaches/view-coaches';
import Teams from '../../../features/listview/teams/Teams';
import Users from '../../../features/listview/users/Users';
import AdminDashboard from '../../../routes/admin-dashboard/AdminDashboard';
import ArchiveOrg from '../../../routes/archive-org/archive-org';
import ArchiveSchool from '../../../routes/archive-school/archive-school';
import ArchiveTeam from '../../../routes/archive-teams/archive-teams';
import ArchiveUser from '../../../routes/archive-user/archive-user';
import AllClassrooms from '../../../routes/classrooms/all-classrooms';
import CoachProfile from '../../../routes/coach-profile/coach-profile';
import EditUser from '../../../routes/edit-user/edit-user';
import GameArena from '../../../routes/gamearena/gamearena';
import ConnectTwitch from '../../../routes/gamearena/routes/connect-twitch/connect-twitch';
import GamearenaBrowse from '../../../routes/gamearena/routes/gamearena-browse/gamearena-browse';
import GameArenaLive from '../../../routes/gamearena/routes/gamearena-live/gamearena-live';
import GamearenaStreamerPage from '../../../routes/gamearena/routes/gamearena-streamer-page/gamearena-streamer-page';
import GaRecentVideoRoute from '../../../routes/gamearena/routes/gamearena-video/ga-recent-video-route';
import AllGames from '../../../routes/games/AllGames';
import GameOverview from '../../../routes/games/overview/GameOverview';
import GameAllTeams from '../../../routes/games/teams/GameAllTeams';
import Invoices from '../../../routes/invoices/invoices';
import InvoiceOrganizations from '../../../routes/invoices/routes/organizations/invoice-organizations';
import EditSchoolInvoice from '../../../routes/invoices/routes/organizations/routes/edit/edit-school-invoice';
import NewInvoiceOrganization from '../../../routes/invoices/routes/organizations/routes/new/new-invoice-organization';
import InvoiceStudents from '../../../routes/invoices/routes/students/invoice-students';
import EditStudentInvoice from '../../../routes/invoices/routes/students/routes/edit/edit-student-invoice';
import NewInvoiceStudent from '../../../routes/invoices/routes/students/routes/new/new-invoice-student';
import { MatchDetailsReschedule } from '../../../routes/match/components/match-details-reschedule';
import EditGameResults from '../../../routes/match/edit-game-results/edit-game-results';
import EditMatchResults from '../../../routes/match/edit-match-results/edit-match-results';
import EditMatch from '../../../routes/match/edit/edit-match';
import ForfeitMatch from '../../../routes/match/forfeit/forfeit-match';
import SubmitGameResult from '../../../routes/match/submit-game-result/submit-game-result';
import SubmitMatchResults from '../../../routes/match/submit-results/submit-match-results';
import ViewMatch from '../../../routes/match/view-match';
import OrganizationOverview from '../../../routes/organizations/view-organization/routes/organization-overview';
import OrganizationSchools from '../../../routes/organizations/view-organization/routes/organization-schools';
import OrganizationTeams from '../../../routes/organizations/view-organization/routes/organization-teams';
import ViewOrganization from '../../../routes/organizations/view-organization/view-organization';
import Classrooms from '../../../routes/profiles/coach-profile/classrooms/classrooms';
import CoachOverview from '../../../routes/profiles/coach-profile/overview/coach-overview';
import NewClassroom from '../../../routes/profiles/coach-profile/classrooms/new-classroom';
import TrainingContent from '../../../routes/profiles/coach-profile/training-content/training-content';
import ViewCoach from '../../../routes/profiles/coach-profile/view-coach';
import UserStats from '../../../routes/profiles/view-profiles/user-stats';
import ViewUser from '../../../routes/profiles/view-profiles/view-user';
import { AllSchedule } from '../../../routes/schedule/AllSchedules';
import SchoolStudents from '../../../routes/schools/view-school/routes/school-students/components/SchoolStudents';
import SchoolTeams from '../../../routes/schools/view-school/routes/school-teams/components/SchoolTeams';
import ViewSchool from '../../../routes/schools/view-school/view-school';
import AllSeasons from '../../../routes/seasons/routes/all/all-seasons';
import ArchiveSeason from '../../../routes/seasons/routes/all/routes/archive/archive-season';
import EditSeasonTeams from '../../../routes/seasons/routes/all/routes/edit-teams/edit-season-teams';
import EditSeason from '../../../routes/seasons/routes/all/routes/edit/edit-season';
import NewSeason from '../../../routes/seasons/routes/all/routes/new/new-season';
import { SeasonsByGame } from '../../../routes/seasons/routes/game/seasons-by-game';
import { GenerateMatch } from '../../../routes/seasons/routes/matches/generate-match';
import { Seasons } from '../../../routes/seasons/seasons';
import ArchiveSponsor from '../../../routes/sponsors/routes/archive-sponsor/archive-sponsor';
import EditSponsor from '../../../routes/sponsors/routes/edit-sponsor/edit-sponsor';
import NewSponsor from '../../../routes/sponsors/routes/new-sponsor/new-sponsor';
import Sponsors from '../../../routes/sponsors/sponsors';
import ManageStudentSubscription from '../../../routes/student-subscriptions/routes/manage-student-subscription';
import TeamViewStats from '../../../routes/teams/view-team/routes/stats/team-view-stats';
import AddStudent from '../../../routes/teams/view-team/routes/team-members/components/add-student';
import TeamMembers from '../../../routes/teams/view-team/routes/team-members/team-members';
import TeamSchedule from '../../../routes/teams/view-team/routes/team-schedule/team-schedule';
import ViewTeam from '../../../routes/teams/view-team/view-team';
import AllTournaments from '../../../routes/tournaments/routes/all/all-tournaments';
import ArchiveTournament from '../../../routes/tournaments/routes/all/routes/archive/archive-tournament';
import DuplicateTournament from '../../../routes/tournaments/routes/all/routes/duplicate/duplicate-tournament';
import EditTournament from '../../../routes/tournaments/routes/all/routes/edit/edit-tournament';
import NewTeamTournament from '../../../routes/tournaments/routes/all/routes/new/new-team-tournament';
import NewTournament from '../../../routes/tournaments/routes/all/routes/new/new-tournament';
import TournamentsByDivision from '../../../routes/tournaments/routes/by-division/tournaments-by-division';
import TournamentsByGame from '../../../routes/tournaments/routes/by-game/tournaments-by-game';
import TournamentCategoryDivisions from '../../../routes/tournaments/routes/divisions/category-divisions';
import TournamentSubdivisions from '../../../routes/tournaments/routes/subdivisions/subdivisions';
import GenerateBracket from '../../../routes/tournaments/routes/torunament-bracket/GenerateBracket';
import EditBracket from '../../../routes/tournaments/routes/torunament-bracket/edit-bracket';
import EditSingleBracket from '../../../routes/tournaments/routes/torunament-bracket/edit-single-bracket';
import Tournaments from '../../../routes/tournaments/tournaments';
import ViewClassroom from '../../../routes/view-classroom/view-classroom';
import NewSeasonWeek from '../../../routes/view-season/routes/new-week/new-season-week';
import ViewSeason from '../../../routes/view-season/view-season';
import { TournamentMatches } from '../../../routes/view-tournament/tournament-matches';
import TournamentOverview from '../../../routes/view-tournament/tournament-overview';
import TournamentStandings from '../../../routes/view-tournament/tournament-standings';
import TournamentTeams from '../../../routes/view-tournament/tournament-teams';
import Tournament from '../../../routes/view-tournament/view-tournament';
import Dashboard from '../../Dashboard';
import Calendar from '../../../routes/calendar-view/calendar';
import AllNotifications from '../../../routes/notifications/all-notification';
import DisputeMatch from '../../../routes/match/dispute-match/dispute-match';
import ViewReschedules from '../../../routes/match/view-reschedules/view-reschedules';
import ViewDisputes from '../../../routes/match/view-disputes/view-disputes';
import ClassroomsScheduleHours from '../../../routes/classrooms/route/classrooms-schedule-hours';
import StudentBookHours from '../../../routes/classrooms/route/student-book-hours';
import ViewLesson from '../../../routes/view-lesson/view-lesson';
import PreviewClassroom from '../../../routes/preview-classroom/preview-classroom';
import OgmaProfile from '../../../routes/ogma-profile/ogma-profile';
import OgmaProfileInvoices from '../../../routes/ogma-profile/ogma-profile-invoices';
import OgmaProfileSchools from '../../../routes/ogma-profile/ogma-profile-schools';
import ViewGuardian from '../../../routes/profiles/guardian-profile/view-guardian';
import GuardianMyStudents from '../../../routes/profiles/guardian-profile/students/guardian-my-students';
import AddMyStudent from '../../../routes/profiles/guardian-profile/students/add-my-student';
import EditClassroom from '../../../routes/profiles/coach-profile/classrooms/edit-classroom';
import ArchiveClassroom from '../../../routes/profiles/coach-profile/classrooms/archive-classroom';
import Lessons from '../../../routes/profiles/coach-profile/training-content/Lessons';
import NewLesson from '../../../routes/profiles/coach-profile/training-content/new-lesson';
import EditLesson from '../../../routes/profiles/coach-profile/training-content/edit-lesson';
import DeleteLesson from '../../../routes/profiles/coach-profile/training-content/delete-lesson';
import AddLessonContent from '../../../routes/profiles/coach-profile/training-content/add-lesson-content';
import OrganizationCoaches from '../../../routes/organizations/view-organization/routes/organization-coaches';
import OrganizationAddCoaches from '../../../routes/organizations/view-organization/routes/organization-add-coaches';
import NewCoachModal from '../../../features/listview/coaches/new-coach-modal';
import UpdateCoachModal from '../../../features/listview/coaches/update-coach-modal';
import ArchiveCoachModal from '../../../features/listview/coaches/archive-coach-modal';
import Support from '../../../routes/support/support';
import ViewInvoice from '../../../routes/invoices/routes/view-invoice/view-invoice';
import NewDivision from '../../../routes/tournaments/routes/divisions/new-division';
import EditDivision from '../../../routes/tournaments/routes/divisions/edit-division';
import ArchiveDivision from '../../../routes/tournaments/routes/divisions/archive-division';
import NewSubDivisions from '../../../routes/tournaments/routes/subdivisions/new-subdivision';
import EditSubDivision from '../../../routes/tournaments/routes/subdivisions/edit-subdivision';
import ArchiveSubDivision from '../../../routes/tournaments/routes/subdivisions/archive-subdivision';
import SeasonByDivision from '../../../routes/seasons/routes/divison/seasons-by-division';
import SuccessSettings from '../../../routes/settings/success-settings';
import SponsorPlacement from '../../../routes/sponsors/routes/placement/sponsor-placement';
import NewSponsorPlacement from '../../../routes/sponsors/routes/placement/new/new-sponsor-placement';
import ArchiveTicket from '../../../routes/support/components/archive-ticket';
import ViewTicketAdmin from '../../../routes/support/view-ticket-admin';
import SupportCategories from '../../../routes/support/support-categories-admin';
import NewCategoryModal from '../../../features/listview/NewModals/NewCategoryModal';
import EditSupportCategory from '../../../routes/support/edit-support-category';
// import DeleteSupportCategory from '../../../routes/support/delete-support-category';
import SupportTicketAdmin from '../../../routes/support/support-tickets-admin';
import DeleteTicket from '../../../routes/support/delete-ticket';
import SupportSubCategories from '../../../routes/support/support-subcategories-admin';
import NewSubCategoryModal from '../../../features/listview/NewModals/NewSubCategoryModal';
import EditSupportSubCategory from '../../../routes/support/edit-support-subcategory';
// import DeleteSupportSubCategory from '../../../routes/support/delete-support-subcategory';
import EditAboutTextCoach from '../../../routes/profiles/coach-profile/overview/edit-about-text-coach';
import NewGame from '../../../routes/games/new-game/new-game';
import NewMatchFormat from '../../../routes/games/new-match-format/new-match-format';
import DeleteGameAccount from '../../../routes/games/overview/add-game/DeleteGameAccount';
import ArchiveSupportCategory from '../../../routes/support/archive-support-category';
import ArchiveSupportSubCategory from '../../../routes/support/archive-support-subcategory';
import Unarchive from '../../../routes/archived/unarchive';
import UnarchiveUser from '../../../routes/archived/routes/user/unarchive-user';
import UnarchiveUserModal from '../../../routes/archived/routes/user/routes/unarchive-user';
import UnarchiveOrganization from '../../../routes/archived/routes/organization/unarchive-organization';
import UnarchiveOrganizationModal from '../../../routes/archived/routes/organization/routes/unarchive-organization';
import UnarchiveTournament from '../../../routes/archived/routes/tournament/unarchive-tournament';
import UnarchiveDivision from '../../../routes/archived/routes/division/unarchive-division';
import UnarchiveDivisionModal from '../../../routes/archived/routes/division/routes/unarchive-division-modal';
import UnarchiveSubdivision from '../../../routes/archived/routes/subdivision/unarchive-subdivision';
import UnarchiveSubdivisionModal from '../../../routes/archived/routes/subdivision/routes/unarchive-subdivision-modal';
import UnarchiveTournamentModal from '../../../routes/archived/routes/tournament/routes/unarchive-tournament-modal';
import UnarchiveSeason from '../../../routes/archived/routes/season/unarchived-season';
import UnarchiveSeasonModal from '../../../routes/archived/routes/season/routes/unarchive-season-modal';
import UnarchiveSponsor from '../../../routes/archived/routes/sponsor/unarchived-sponsor';
import UnarchiveSponsorModal from '../../../routes/archived/routes/sponsor/routes/unarchive-sponsor-modal';
import UnarchiveSchool from '../../../routes/archived/routes/school/unarchive-school';
import UnarchiveSchoolModal from '../../../routes/archived/routes/school/routes/unarchive-school-modal';
import UnarchiveTeam from '../../../routes/archived/routes/team/unarchive-team';
import UnarchiveTeamModal from '../../../routes/archived/routes/team/routes/unarchive-team-modal';
import EditGame from '../../../routes/games/edit-game/edit-game';
import ArchiveGame from '../../../routes/games/archive-game/archive-game';
import UnarchiveGame from '../../../routes/archived/routes/game/unarchive-game';
import UnarchiveClassroom from '../../../routes/archived/routes/classroom/unarchive-classroom';
import UnarchiveClassroomModal from '../../../routes/archived/routes/classroom/routes/unarchive-classroom-modal';
import UpdateGameResults from '../../../routes/update-game-result/update-game-result';
import EndSeason from '../../../routes/seasons/routes/all/routes/end/end-season';
import TermsAndConditionsRoute from '../../../routes/terms-and-conditions/terms-and-conditions';
import OgmaInvitedUsers from '../../../routes/ogma-invited-users/ogma-invited-users';
import DeleteClassroom from '../../../routes/profiles/coach-profile/classrooms/delete-classroom';
import InviteStudentsOgma from '../../../routes/ogma-dashboard/route/invite-students/invite-students-ogma';
import ConnectTicketModal from '../../../routes/support/ConnectTicketModal';
import OrganizationOgmas from '../../../routes/organizations/view-organization/routes/organization-ogma';
import InternalTickets from '../../../routes/support/internal-tickets';
import ExternalTickets from '../../../routes/support/external-tickets';
import OpenTicketsType from '../../../routes/support/open-tickets-type';
import InternalClosedTickets from '../../../routes/support/internal-closed-tickets';
import ExternalClosedTickets from '../../../routes/support/external-closed-tickets';
import InternalArchivedTickets from '../../../routes/support/internal-archived-tickets';
import ExternalArchivedTickets from '../../../routes/support/external-archived-tickets';
import ChangeTicketTier from '../../../routes/support/change-tier-ticket';
import ApproveTicketAdmin from '../../../routes/support/approve-ticket-admin';
import DisconnectChildTicketModal from '../../../routes/support/disconnect-ticket';
import DeleteTicketComment from '../../../routes/support/delete-ticket-comments';
import ApproveOrDisapproveTicketAdmin from '../../../routes/support/approve-disapprove-ticket-admin';
import TeamOrganizations from '../../../routes/teams/view-team/routes/team-organizations/team-organizations.route';
import AllOpenTickets from '../../../routes/support/all-tickets-open';
import AllClosedTickets from '../../../routes/support/all-tickets-closed';
import AllArchivedTickets from '../../../routes/support/all-tickets-archived';
import UnArchiveCategory from '../../../routes/support/unarchive-support-category';
import UnArchiveSubcategory from '../../../routes/support/unarchive-support-subcategory';
import UnarchiveCategoryModal from '../../../routes/support/unarchive-support-category-modal';
import UnarchiveSubcategoryModal from '../../../routes/support/unarchive-support-subcategory-modal';
import EditSeasonWeek from '../../../routes/view-season/routes/edit-week/edit-season-week';
import EditMatchTeam from '../../../routes/match/edit-match-players/edit-match-team';
import DeleteMatchTeam from '../../../routes/match/edit-match-players/delete-match-tean';
import DeleteMatch from '../../../routes/delete-match/delete-match';
import CancelMatch from '../../../routes/cancel-match/cancel-match';
import UncancelMatch from '../../../routes/match/uncancel/uncancel-match';
import AddMatchToWeek from '../../../routes/add-match-to-week/add-match-to-week';
import RemoveTeamSeason from '../../../routes/remove-team/remove-team-season.route';
import NewTicketAdmin from '../../../routes/support/new-ticket-admin';
import EditReschedule from '../../../routes/match/view-reschedules/edit-reschedule';
import CancelReschedule from '../../../routes/match/view-reschedules/cancel-reschedule';
import UnforfeitMatch from '../../../routes/match/unforfeit/unforfeit-match';
import FinalDeleteMatch from '../../../routes/match/delete/delete-match';
import DeleteMatchGame from '../../../routes/match/delete-match-game/delete-match-game';
import OgmaInvitedUsersCancel from '../../../routes/ogma-invited-users/ogma-invited-users-cancel';
import UnforfeitDoubleMatch from '../../../routes/match/unforfeit/double-unforfeit-match';
import AddSeasonTeams from '../../../routes/seasons/routes/all/routes/add-teams/add-season-teams';
import ViewForfeits from '../../../routes/match/forfeit/view-forfeits';
import BenchTeam from '../../../features/listview/teams/bench/bench-team';
import UnBenchTeam from '../../../features/listview/teams/unbench/unbench-team';
import DeleteUser from '../../../routes/delete-user/delete-user';
import DeletedUsers from '../../../routes/deleted-users/deleted-users';

import Subdivisions from '../../../routes/tournaments/routes/subdivisions/subdivisions';
import CategoryDivisions from '../../../routes/tournaments/routes/divisions/category-divisions';
import UnarchiveGameModal from '../../../routes/archived/routes/game/routes/unarchive-game-modal';
import NewSchoolInviteLink from '../../../routes/school-invite/new-school-invite-link';
import TeamSeasonSelection from '../../../features/listview/teams/TeamSeasonSelection';
import DisbandTeam from '../../../routes/teams/view-team/routes/team-members/components/disband';
import DeleteSeasonWeek from '../../../routes/view-season/routes/delete-week/delete-season-week';
import CheckInTeam from '../../../routes/match/check-in/check-in-team';
import CheckOutTeam from '../../../routes/match/check-out/check-out-team';
import NewSeasonStage from '../../../routes/view-season/routes/new-stage/new-season-stage';
import EditSeasonStage from '../../../routes/seasons/routes/all/routes/edit-stage/edit-season-stage';
import DeleteSeasonStage from '../../../routes/view-season/routes/delete-stage/delete-season-stage';
import CompleteSeasonStage from '../../../routes/view-season/routes/complete-stage/complete-season-stage';
import DisputeMatchChat from '../../../routes/match/dispute-match-chat/dispute-match-chat';
import ResolveMatchChatDisputes from '../../../routes/match/dispute-match-chat/resolve-match-chat-disputes';
import MatchChatDisputes from '../../../routes/match-chat-disputes/match-chat-disputes';
import ViewImage from '../../../routes/match/dispute-match-chat/view-image';
import JoinWeek from '../../../routes/seasons/routes/all/routes/join-week/join-week';
import EditGroups from '../../../routes/view-season/routes/edit-groups/edit-groups';
import EditGameRaceTimes from '../../../routes/match/edit-game-race-times/edit-game-race-times';
import LeaderboardMatchChat from '../../../routes/match/components/leaderboard-match-chat';
export const adminProtectedRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='./dashboard' />,
  },
  {
    path: 'termsofservices',
    element: <TermsAndConditionsRoute />,
  },
  {
    path: 'calendar',
    element: <Calendar />,
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'notifications',
    element: <AllNotifications />,
  },
  {
    path: '/dashboard/admin',
    element: <AdminDashboard />,
    children: [
      {
        path: 'invite-students',
        element: <InviteStudentsOgma />,
        children: [
          {
            path: 'new-school-link/:id',
            element: <NewSchoolInviteLink />,
          },
        ],
      },
    ],
  },
  {
    path: 'gamearena',
    element: <GameArena />,
    children: [
      {
        path: 'connect',
        element: <ConnectTwitch />,
      },
      {
        path: 'video/:vid',
        element: <GaRecentVideoRoute />,
      },
    ],
  },
  {
    path: 'gamearena/live/:id',
    element: <GameArenaLive />,
  },

  {
    path: 'gamearena/streamer/:id',
    element: <GamearenaStreamerPage />,
    children: [
      {
        path: 'video/:vid',
        element: <GaRecentVideoRoute />,
      },
    ],
  },
  {
    path: 'gamearena/browse',
    element: <GamearenaBrowse />,
  },
  {
    path: 'match/:id',
    element: <ViewMatch />,
    children: [
      {
        path: 'submit',
        element: <SubmitMatchResults />,
      },
      {
        path: 'dispute-chat',
        element: <DisputeMatchChat />,
      },
      {
        path: 'chat-disputes',
        element: <ResolveMatchChatDisputes />,
        children: [
          {
            path: 'view/:disputeId',
            element: <ViewImage />,
          },
        ],
      },
      // {
      //   path: 'dispute',
      //   element: <DisputeMatch />,
      // },
      {
        path: 'edit',
        element: <EditMatch />,
      },
      {
        path: 'edit-result',
        element: <EditMatchResults />,
      },
      {
        path: 'forfeit',
        element: <ForfeitMatch />,
      },
      {
        path: 'unforfeit',
        element: <UnforfeitMatch />,
      },
      {
        path: 'unforfeit-double',
        element: <UnforfeitDoubleMatch />,
      },
      {
        path: 'delete',
        element: <FinalDeleteMatch />,
      },
      {
        path: 'cancel',
        element: <CancelMatch />,
      },
      {
        path: 'uncancel',
        element: <UncancelMatch />,
      },
      // {
      //   path: 'reschedule',
      //   element: <MatchDetailsReschedule />,
      // },
      {
        path: 'view-reschedules',
        element: <ViewReschedules />,
        children: [
          {
            path: 'edit/:rescheduleId',
            element: <EditReschedule />,
          },
          {
            path: 'cancel/:rescheduleId',
            element: <CancelReschedule />,
          },
        ],
      },
      {
        path: 'view-disputes',
        element: <ViewDisputes />,
      },
      {
        path: 'view-forfeits',
        element: <ViewForfeits />,
      },
      {
        path: 'edit-game-results/:gid',
        element: <EditGameResults />,
      },
      {
        path: 'edit-game-race-times/:rid/:uid',
        element: <EditGameRaceTimes />,
      },
      {
        path: 'update-game-results/:gid',
        element: <UpdateGameResults />,
      },
      {
        path: 'submit-game-results/:gid',
        element: <SubmitGameResult />,
      },
      {
        path: 'delete-match-game/:gid',
        element: <DeleteMatchGame />,
      },
      {
        path: 'bench/:bId',
        element: <BenchTeam />,
      },
      {
        path: 'unbench/:bId',
        element: <UnBenchTeam />,
      },
      {
        path: 'check-in/:teamId/',
        element: <CheckInTeam />,
      },
      {
        path: 'check-out/:teamId/',
        element: <CheckOutTeam />,
      },
      {
        path: 'check-in/all/:teamId1/:teamId2',
        element: <CheckInTeam />,
      },
      {
        path: 'check-out/all/:teamId1/:teamId2',
        element: <CheckOutTeam />,
      },
    ],
  },
  {
    path: 'coach',
    element: <CoachProfile />,
  },
  {
    path: '/tournaments/by-game/:id',
    element: <TournamentsByGame />,
  },
  { path: '/seasons/by-game/:id', element: <SeasonsByGame /> },
  {
    path: '/tournaments/by-division/:id',
    element: <TournamentsByDivision />,
  },
  {
    path: '/seasons/by-division/:id',
    element: <SeasonByDivision />,
  },
  {
    path: 'seasons/view/:id',
    element: <ViewSeason />,
    children: [
      {
        path: 'remove-team',
        element: <RemoveTeamSeason />,
      },
      {
        path: 'edit-teams',
        element: <EditSeasonTeams />,
      },
      {
        path: 'add-teams',
        element: <AddSeasonTeams />,
      },
      {
        path: 'generate-matches/:weekid',
        element: <GenerateMatch type='season' />,
      },
      {
        path: 'edit-week/:weekid',
        element: <EditSeasonWeek />,
      },
      {
        path: 'delete-week/:weekId',
        element: <DeleteSeasonWeek />,
      },
      {
        path: 'new-week/:stageId',
        element: <NewSeasonWeek />,
      },
      {
        path: 'edit-season',
        element: <EditSeason />,
      },
      {
        path: 'end-season',
        element: <EndSeason />,
      },
      {
        path: 'edit-team/:matchId',
        element: <EditMatchTeam />,
      },
      {
        path: 'delete-team/:matchId',
        element: <DeleteMatchTeam />,
      },
      {
        path: 'delete-match/:matchid',
        element: <DeleteMatch />,
      },
      {
        path: 'add-match',
        element: <AddMatchToWeek />,
      },
      {
        path: 'bench/:bId',
        element: <BenchTeam />,
      },
      {
        path: 'unbench/:bId',
        element: <UnBenchTeam />,
      },
      {
        path: 'new-stage',
        element: <NewSeasonStage />,
      },
      {
        path: 'edit-stage/:stageId',
        element: <EditSeasonStage />,
      },
      {
        path: 'delete-stage/:stageId',
        element: <DeleteSeasonStage />,
      },
      {
        path: 'complete-stage/:stageId',
        element: <CompleteSeasonStage />,
      },
      {
        path: 'join-week',
        element: <JoinWeek />,
      },
      {
        path: 'edit-groups/:stageId',
        element: <EditGroups />,
      },
      {
        path: 'chat/:matchId',
        element: <LeaderboardMatchChat />,
        children: [
          {
            path: 'dispute-chat',
            element: <DisputeMatchChat />,
          },
          {
            path: 'chat-disputes',
            element: <ResolveMatchChatDisputes />,
            children: [
              {
                path: 'view/:disputeId',
                element: <ViewImage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'seasons',
    element: <Seasons />,
    children: [
      {
        path: 'all',
        element: <AllSeasons />,
        children: [
          {
            path: 'new',
            element: <NewSeason />,
          },
          {
            path: 'edit/:id',
            element: <EditSeason />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveSeason />,
          },
        ],
      },
      {
        path: 'division',
        element: <CategoryDivisions />,
        children: [
          {
            path: 'new',
            element: <NewDivision />,
          },
          {
            path: 'edit/:id',
            element: <EditDivision />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveDivision />,
          },
        ],
      },
      {
        path: 'subdivision',
        element: <Subdivisions />,
        children: [
          {
            path: 'new',
            element: <NewSubDivisions />,
          },
          {
            path: 'edit/:id',
            element: <EditSubDivision />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveSubDivision />,
          },
        ],
      },
    ],
  },
  {
    path: 'invoices/view/:id',
    element: <ViewInvoice />,
  },

  {
    path: 'invoices',
    element: <Invoices />,
    children: [
      {
        path: 'schools',
        element: <InvoiceOrganizations />,
        children: [
          {
            path: 'new',
            element: <NewInvoiceOrganization />,
          },
          {
            path: 'edit/:id',
            element: <EditSchoolInvoice />,
          },
        ],
      },
      {
        path: 'students',
        element: <InvoiceStudents />,
        children: [
          {
            path: 'new',
            element: <NewInvoiceStudent />,
          },
          {
            path: 'edit/:id',
            element: <EditStudentInvoice />,
          },
        ],
      },
    ],
  },
  {
    path: 'classrooms',
    element: <AllClassrooms />,
    children: [
      {
        path: 'schedule-ogma/:id',
        element: <ClassroomsScheduleHours />,
      },
      {
        path: 'book-student/:bookId',
        element: <StudentBookHours />,
      },
      // {
      //   path: 'view/:id',
      //   element: <ViewClassroom />,
      // },
    ],
  },

  {
    path: 'classrooms/view/:id',
    element: <ViewClassroom />,
    children: [
      {
        path: 'l/:lessonid/content/:publicId',
        element: <ViewLesson />,
      },
    ],
  },
  {
    path: 'classrooms/preview/:id',
    element: <PreviewClassroom />,
  },
  {
    path: 'tournaments',
    element: <Tournaments />,
    children: [
      {
        path: 'all',
        element: <AllTournaments />,
        children: [
          {
            path: 'new',
            element: <NewTournament />,
          },
          {
            path: 'edit/:id',
            element: <EditTournament />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveTournament />,
          },
          {
            path: 'duplicate/:id',
            element: <DuplicateTournament />,
          },
        ],
      },
      {
        path: 'divisions',
        element: <TournamentCategoryDivisions />,
        children: [
          {
            path: 'new',
            element: <NewDivision />,
          },
          {
            path: 'edit/:id',
            element: <EditDivision />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveDivision />,
          },
        ],
      },

      {
        path: 'subdivisions',
        element: <TournamentSubdivisions />,
        children: [
          {
            path: 'new',
            element: <NewSubDivisions />,
          },
          {
            path: 'edit/:id',
            element: <EditSubDivision />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveSubDivision />,
          },
        ],
      },
    ],
  },
  {
    path: 'tournaments/view/:id',
    element: <Tournament />,
    children: [
      {
        path: 'edit-tournament',
        element: <EditTournament />,
      },
      {
        path: '',
        element: <TournamentOverview />,
      },
      {
        path: 'bracket',
        element: <Bracket />,
        children: [
          {
            path: 'generate-bracket',
            element: <GenerateBracket />,
          },
          {
            path: 'edit',
            element: <EditBracket />,
          },
          {
            path: 'edit-bracket/:bracketid',
            element: <EditSingleBracket />,
          },
          // {
          //   path: "manual-bracket",
          //   element: <ManualBracket />,
          // }
        ],
      },

      {
        path: 'matches',
        element: <TournamentMatches />,
      },
      // {
      //   path: "add",
      //   element: <NewTeamTournament />
      // },
      {
        path: 'teams',
        element: <TournamentTeams />,
        children: [
          {
            path: 'edit',
            element: <NewTeamTournament />,
          },
        ],
      },
      {
        path: 'standings',
        element: <TournamentStandings />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '*',
    // element: <Dashboard />,
  },
  {
    path: 'Users',
    element: <Users />,
    children: [
      {
        path: 'new',
        element: <Modal />,
      },
      {
        path: 'edit/:id',
        element: <EditUser />,
      },
      {
        path: 'archive/:id',
        element: <ArchiveUser />,
      },
      {
        path: 'delete/:id',
        element: <DeleteUser />,
      },
    ],
  },
  {
    path: 'teams/view/:id',
    element: <ViewTeam />,
    children: [
      {
        path: '',
        element: <TeamViewStats />,
      },
      {
        path: 'members',
        element: <TeamMembers />,
        children: [
          {
            path: 'add',
            element: <AddStudent onTeam />,
          },
          {
            path: 'disband',
            element: <DisbandTeam />,
          },
        ],
      },
      {
        path: 'organizations',
        element: <TeamOrganizations />,
      },
      // {
      //   path: 'stats',
      //   element: <TeamViewStats />,
      // },
      {
        path: 'team-schedule',
        element: <TeamSchedule />,
      },
      {
        path: 'bench/:bId',
        element: <BenchTeam />,
      },
      {
        path: 'unbench/:bId',
        element: <UnBenchTeam />,
      },
      {
        path: 'add-to-season',
        element: <TeamSeasonSelection />,
      },
    ],
  },
  {
    path: 'users/view/:id',
    children: [
      {
        path: 'ogma',
        element: <OgmaProfile />,
        children: [
          {
            path: 'invoices',
            element: <OgmaProfileInvoices />,
          },
          {
            path: 'schools',
            element: <OgmaProfileSchools />,
          },
        ],
      },
      {
        path: 'guardian',
        element: <ViewGuardian />,
        children: [
          // {
          //   path: 'overview',
          //   element: <GuardianOverview />,
          // },
          {
            path: 'overview',
            element: <GuardianMyStudents />,
            children: [
              {
                path: 'new',
                element: <AddMyStudent />,
              },
              {
                path: 'manage/:subscriptionId',
                element: <ManageStudentSubscription />,
              },
            ],
          },
        ],
      },
      {
        path: 'student',
        element: <ViewUser />,
        children: [
          // {
          //   path: 'overview',
          //   element: <UserOverview />,
          // },
          {
            path: 'overview',
            element: <UserStats />,
          },
          // {
          //   path: 'usernames',
          //   element: <StudentUsernames />,
          // },
        ],
      },
      {
        path: 'coach',
        element: <ViewCoach />,
        children: [
          {
            path: 'overview',
            element: <CoachOverview />,
            children: [
              {
                path: 'edit-about',
                element: <EditAboutTextCoach />,
              },
            ],
          },
          // FIXME: UNCOMMENT TO ENABLE CLASSROOMS
          // {
          //   path: 'classrooms',
          //   element: <Classrooms />,

          // },
          // {
          //   path: 'training',
          //   element: <TrainingContent />,
          //   children: [
          //     {
          //       path: 'new',
          //       element: <NewClassroom />,
          //     },
          //     {
          //       path: 'edit/:cId',
          //       element: <EditClassroom />,
          //     },
          //     {
          //       path: 'archive/:cId',
          //       element: <ArchiveClassroom />,
          //     },
          //   ],
          // },
          // {
          //   path: 'training/lesson/:cId',
          //   element: <Lessons />,
          //   children: [
          //     {
          //       path: 'new',
          //       element: <NewLesson />,
          //     },
          //     {
          //       path: 'edit/:lId',
          //       element: <EditLesson />,
          //     },
          //     {
          //       path: 'delete/:lId',
          //       element: <DeleteLesson />,
          //     },
          //     {
          //       path: 'content/:lId',
          //       element: <AddLessonContent />,
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    path: 'coaches/view/:id',
    element: <ViewCoach />,
    children: [
      {
        path: 'overview',
        element: <CoachOverview />,
      },
      {
        path: 'classrooms',
        element: <Classrooms />,
        children: [
          {
            path: 'new',
            element: <NewClassroom />,
          },
        ],
      },
      {
        path: 'training',
        element: <TrainingContent />,
        children: [
          {
            path: 'new',
            element: <NewClassroom />,
          },
          {
            path: 'edit/:cId',
            element: <EditClassroom />,
          },
          {
            path: 'archive/:cId',
            element: <ArchiveClassroom />,
          },
          {
            path: 'delete/:lid',
            element: <DeleteClassroom />,
          },
        ],
      },
      {
        path: 'training/lesson/:cId',
        element: <Lessons />,
        children: [
          {
            path: 'new',
            element: <NewLesson />,
          },
          {
            path: 'edit/:lId',
            element: <EditLesson />,
          },
          {
            path: 'delete/:lId',
            element: <DeleteLesson />,
          },
          {
            path: 'content/:lId',
            element: <AddLessonContent />,
          },
        ],
      },
    ],
  },
  {
    path: 'organization',
    element: <ViewOrganization />,
    children: [
      {
        path: '',
        element: <OrganizationOverview />,
      },
      {
        path: 'schools',
        element: <OrganizationSchools />,
      },
      {
        path: 'teams',
        element: <OrganizationTeams />,
      },
      {
        path: 'coaches',
        element: <OrganizationCoaches />,
        children: [
          {
            path: 'add',
            element: <OrganizationAddCoaches />,
          },
        ],
      },
    ],
  },
  {
    path: 'organizations/view/:id',
    element: <ViewOrganization />,
    children: [
      {
        path: '',
        element: <OrganizationSchools />,
      },
      // {
      //   path: 'schools',
      //   element: <OrganizationSchools />,
      // },
      {
        path: 'teams',
        element: <OrganizationTeams />,
      },
      {
        path: 'coaches',
        element: <OrganizationCoaches />,
        children: [
          {
            path: 'add',
            element: <OrganizationAddCoaches />,
          },
        ],
      },
      {
        path: 'ogma',
        element: <OrganizationOgmas />,
      },
    ],
  },
  {
    path: 'Teams',
    element: <Teams />,
    children: [
      {
        path: 'new',
        element: <NewTeamModal />,
      },
      {
        path: 'edit/:id',
        element: <UpdateTeamModal />,
      },
      {
        path: 'archive/:id',
        element: <ArchiveTeam />,
      },
      {
        path: 'view/:id',
        element: <ViewTeam />,
      },
      {
        path: 'bench/:id',
        element: <BenchTeam />,
      },
      {
        path: 'unbench/:id',
        element: <UnBenchTeam />,
      },
    ],
  },
  {
    path: 'schools/view/:id',
    element: <ViewSchool />,
    children: [
      {
        path: '',
        element: <SchoolStudents />,
      },
      {
        path: 'students',
        element: <SchoolStudents />,
      },
      {
        path: 'teams',
        element: <SchoolTeams />,
      },
    ],
  },
  {
    path: 'coaches',
    element: <ViewCoaches />,
    children: [
      {
        path: 'new',
        element: <NewCoachModal />,
      },
      {
        path: 'edit/:id',
        element: <UpdateCoachModal />,
      },
      {
        path: 'archive/:id',
        element: <ArchiveCoachModal />,
      },
    ],
    // children: [
    //   {
    //     path: "overview",
    //     element: <SchoolOverview />,
    //   },
    //   {
    //     path: "students",
    //     element: <SchoolStudents />,
    //   },
    //   {
    //     path: "teams",
    //     element: <SchoolTeams />,
    //   },
    // ],
  },
  {
    path: 'sponsors',
    element: <Sponsors />,
    children: [
      {
        path: 'placement',
        element: <SponsorPlacement />,
        children: [
          {
            path: 'new',
            element: <NewSponsorPlacement />,
          },
        ],
      },
      {
        path: 'new',
        element: <NewSponsor />,
      },
      {
        path: 'edit/:id',
        element: <EditSponsor />,
      },
      {
        path: 'archive/:id',
        element: <ArchiveSponsor />,
      },
    ],
  },
  {
    path: 'archived',
    element: <Unarchive />,
    children: [
      {
        path: 'user',
        element: <UnarchiveUser />,
        children: [
          {
            path: 'unarchive/:id/:username',
            element: <UnarchiveUserModal />,
          },
        ],
      },
      {
        path: 'organization',
        element: <UnarchiveOrganization />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveOrganizationModal />,
          },
        ],
      },
      {
        path: 'tournament',
        element: <UnarchiveTournament />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveTournamentModal />,
          },
        ],
      },
      {
        path: 'sponsors',
        element: <UnarchiveSponsor />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveSponsorModal />,
          },
        ],
      },
      {
        path: 'season',
        element: <UnarchiveSeason />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveSeasonModal />,
          },
        ],
      },
      {
        path: 'organization',
        element: <UnarchiveOrganization />,
        children: [
          {
            path: 'unarchive/:id',
            element: <UnarchiveOrganizationModal />,
          },
        ],
      },
      {
        path: 'division',
        element: <UnarchiveDivision />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveDivisionModal />,
          },
        ],
      },
      {
        path: 'subdivision',
        element: <UnarchiveSubdivision />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveSubdivisionModal />,
          },
        ],
      },
      {
        path: 'organization',
        element: <UnarchiveOrganization />,
        children: [
          {
            path: 'unarchive/:id',
            element: <UnarchiveOrganizationModal />,
          },
        ],
      },
      {
        path: 'school',
        element: <UnarchiveSchool />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveSchoolModal />,
          },
        ],
      },
      {
        path: 'team',
        element: <UnarchiveTeam />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveTeamModal />,
          },
        ],
      },
      {
        path: 'classroom',
        element: <UnarchiveClassroom />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveClassroomModal />,
          },
        ],
      },
      {
        path: 'category',
        element: <UnArchiveCategory />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveCategoryModal />,
          },
        ],
      },
      {
        path: 'subcategory',
        element: <UnArchiveSubcategory />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveSubcategoryModal />,
          },
        ],
      },
      {
        path: 'game',
        element: <UnarchiveGame />,
        children: [
          {
            path: 'unarchive/:id/:name',
            element: <UnarchiveGameModal />,
          },
        ],
      },
    ],
  },
  {
    path: '/schools',
    element: <Schools />,
    children: [
      {
        path: 'new',
        element: <NewSchoolModal />,
      },
      {
        path: 'edit/:id',
        element: <UpdateSchoolModal />,
      },
      {
        path: 'archive/:id',
        element: <ArchiveSchool />,
      },
    ],
  },
  {
    path: '/organizations',
    element: <Organizations />,
    children: [
      {
        path: 'new',
        element: <NewOrganizationModal />,
      },
      {
        path: 'edit/:id',
        element: <UpdateOrganizationModal />,
      },
      {
        path: 'archive/:id',
        element: <ArchiveOrg />,
      },
    ],
  },
  {
    path: '/schedule',
    element: <AllSchedule />,
  },

  {
    path: '/games',
    element: <AllGames />,
    children: [
      {
        path: '',
        element: <GameOverview />,
        children: [
          {
            path: 'new',
            element: <NewGame />,
          },
          {
            path: 'newMatchFormat',
            element: <NewMatchFormat />,
          },
          {
            path: 'delete/:id',
            element: <DeleteGameAccount />,
          },
          {
            path: 'edit/:id',
            element: <EditGame />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveGame />,
          },
        ],
      },

      {
        path: 'teams',
        element: <GameAllTeams />,
        // children: [
        //   {
        //     path: 'new',
        //     element: <NewTeamModal />,
        //   },
        // ],
      },
    ],
  },

  // Support

  {
    path: 'support',
    element: <Support />,
    children: [
      {
        path: 'new/ticket',
        element: <NewTicketAdmin />,
      },
      {
        path: 'tickets',
        element: <SupportTicketAdmin />,
        children: [
          {
            path: 'open',
            element: <OpenTicketsType />,
            children: [
              {
                path: 'all',
                element: <AllOpenTickets />,
                children: [
                  {
                    path: 'archive/:id',
                    element: <ArchiveTicket />,
                  },
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                  {
                    path: 'connect/:id',
                    element: <ConnectTicketModal />,
                  },
                ],
              },
              {
                path: 'internal',
                element: <InternalTickets />,
                children: [
                  {
                    path: 'archive/:id',
                    element: <ArchiveTicket />,
                  },
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                  {
                    path: 'connect/:id',
                    element: <ConnectTicketModal />,
                  },
                ],
              },
              {
                path: 'external',
                element: <ExternalTickets />,
                children: [
                  {
                    path: 'connect/:id',
                    element: <ConnectTicketModal />,
                  },
                  {
                    path: 'archive/:id',
                    element: <ArchiveTicket />,
                  },
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
            ],
          },
          {
            path: 'closed',
            element: <OpenTicketsType />,
            children: [
              {
                path: 'all',
                element: <AllClosedTickets />,
                children: [
                  {
                    path: 'archive/:id',
                    element: <ArchiveTicket />,
                  },
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
              {
                path: 'internal',
                element: <InternalClosedTickets />,
                children: [
                  {
                    path: 'archive/:id',
                    element: <ArchiveTicket />,
                  },
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
              {
                path: 'external',
                element: <ExternalClosedTickets />,
                children: [
                  {
                    path: 'archive/:id',
                    element: <ArchiveTicket />,
                  },
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
            ],
          },
          {
            path: 'archived',
            element: <OpenTicketsType />,
            children: [
              {
                path: 'all',
                element: <AllArchivedTickets />,
                children: [
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
              {
                path: 'internal',
                element: <InternalArchivedTickets />,
                children: [
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
              {
                path: 'external',
                element: <ExternalArchivedTickets />,
                children: [
                  {
                    path: 'delete/:id',
                    element: <DeleteTicket />,
                  },
                ],
              },
              {
                path: 'delete/:id',
                element: <DeleteTicket />,
              },
            ],
          },
        ],
      },
      {
        path: 'categories',
        element: <SupportCategories />,
        children: [
          {
            path: 'new',
            element: <NewCategoryModal />,
          },
          {
            path: 'edit/:id',
            element: <EditSupportCategory />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveSupportCategory />,
          },
          // {
          //   path: 'delete/:id',
          //   element: <DeleteSupportCategory />,
          // },
        ],
      },
      {
        path: 'subcategories',
        element: <SupportSubCategories />,
        children: [
          {
            path: 'new',
            element: <NewSubCategoryModal />,
          },
          {
            path: 'edit/:id',
            element: <EditSupportSubCategory />,
          },
          {
            path: 'archive/:id',
            element: <ArchiveSupportSubCategory />,
          },
          // {
          //   path: 'delete/:id',
          //   element: <DeleteSupportSubCategory />,
          // },
        ],
      },
      {
        path: 'viewticket/:id',
        element: <ViewTicketAdmin />,
        children: [
          {
            path: 'change-ticket-tier',
            element: <ChangeTicketTier />,
          },
          {
            path: 'delete-comment/:deleteId',
            element: <DeleteTicketComment />,
          },
          {
            path: 'approve',
            element: <ApproveTicketAdmin />,
          },
          {
            path: 'validate-ticket',
            element: <ApproveOrDisapproveTicketAdmin />,
          },
          {
            path: 'disconnect',
            element: <DisconnectChildTicketModal />,
          },
        ],
      },
    ],
  },

  {
    path: '/Settings',
    element: <Settings />,

    children: [
      {
        path: '',
        element: <MyAccount />,
      },
      {
        path: 'success',
        element: <SuccessSettings />,
      },
      {
        path: 'security/',
        element: <SecuritySettings />,
      },
      {
        path: 'notification/',
        element: <NotificationSettings />,
      },
      {
        path: 'delete/:id',
        element: <DeleteUser />,
      },
    ],
  },

  {
    path: 'invitedusers',
    element: <OgmaInvitedUsers />,
    children: [
      {
        path: 'cancel/:id',
        element: <OgmaInvitedUsersCancel />,
      },
    ],
  },
  {
    path: 'deleted-users',
    element: <DeletedUsers />,
  },
  {
    path: 'match-chat-disputes',
    element: <MatchChatDisputes />,
  },
];
