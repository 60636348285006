import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../slices/auth';
import { selectMatchData } from '../slices/matchSlice';
import { UserRoles } from '../utils/constants';

const useChatTabs: () => [(string | number)[][], number[] | undefined, string | any] = () => {
  const [tabs, setTabs] = useState<(string | number)[][]>([[0, 'All']]);
  const [rooms, setRooms] = useState<number[] | undefined>(undefined);
  const [partOfTeam, setPartOfTeam] = useState<(string | any)[][]>();

  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  useEffect(() => {
    if (!user) return;
    if (user.roles === UserRoles.Admin) {
      setTabs(() => {
        const arr = [[0, 'All']];
        if (matchData?.home_team?.length) arr.push([matchData?.home_team?.[0]?.id, 'Home']);
        if (matchData?.away_team?.length) arr.push([matchData?.away_team?.[0]?.id, 'Away']);
        return arr;
      });
      // setRooms([0, matchData?.home_team[0].id, matchData?.away_team[0].id]);
      setRooms(() => {
        const arr = [];
        if (matchData?.home_team?.length) arr.push(matchData?.home_team?.[0]?.id);
        if (matchData?.away_team?.length) arr.push(matchData?.away_team?.[0]?.id);
        return arr;
      });
    } else if (user.roles === UserRoles.Ogma) {
      const ogmaTeams: [number, string][] = [];
      const homeOgmas = [
        matchData?.home_team?.[0]?.organizations?.map((org: any) => org.user?.id),

        matchData?.home_team?.[0]?.School?.organization?.user?.id,
        ...(matchData?.home_team?.[0]?.organizations?.map((org: any) =>
          org.ogmas?.map((o: { id: number }) => o.id),
        ) ?? []),
        ...(matchData?.home_team?.[0]?.School?.organization?.ogmas?.map(
          (o: { id: number }) => o.id,
        ) ?? []),
      ].flat();
      const awayOgmas = [
        matchData?.away_team?.[0]?.organizations?.map((org: any) => org.user?.id),
        matchData?.away_team?.[0]?.School?.organization?.user?.id,
        ...(matchData?.away_team?.[0]?.organizations?.map((org: any) =>
          org.ogmas?.map((o: { id: number }) => o.id),
        ) ?? []),

        ...(matchData?.away_team?.[0]?.School?.organization?.ogmas?.map(
          (o: { id: number }) => o.id,
        ) ?? []),
      ].flat();
      if (
        matchData?.home_team?.length &&
        homeOgmas?.filter((o) => o === user.id)?.length
        // matchData?.home_team[0].School?.organization_id === user.organizations[0].id
      )
        ogmaTeams.push([matchData?.home_team[0].id, 'Home']);
      if (
        matchData?.away_team?.length &&
        awayOgmas.filter((o) => o === user.id).length
        // matchData?.away_team[0].School?.organization_id === user.organizations[0].id
      )
        ogmaTeams.push([matchData?.away_team[0].id, 'Away']);

      setTabs([[0, 'All'], ...ogmaTeams]);
      setRooms(ogmaTeams.map((o) => o[0]));
      setPartOfTeam(ogmaTeams);
    } else if (user.roles === UserRoles.Student) {
      setRooms([]);
    } else {
      setTabs([[0, 'All']]);
      setRooms([]);
    }
  }, [user, matchData]);

  // useEffect(() => {
  //   if (user.roles === UserRoles.Student) {
  //     setRooms([]);
  //     return;
  //   }
  //   for (const [room, text] of tabs) {
  //     if (room)
  //       setRooms((r) => {
  //         if (r) return [...new Set([...r, room])];
  //         else return [...new Set([room])];
  //       });
  //     else setRooms([]);
  //   }
  // }, [user, matchData, tabs]);

  return [tabs, rooms, partOfTeam];
};

export default useChatTabs;
